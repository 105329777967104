var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4d7d50c83e158b29007270e7d3ad6b0e75aca3a2"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { excludeGraphQLFetch } from "apollo-link-sentry";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: !!process.env.ARAGRO_ENV,
  environment: process.env.ARAGRO_ENV,
  dsn:
    SENTRY_DSN ||
    "https://ad0a1b3a7b1942f0a2a475618e3076e3@o82583.ingest.sentry.io/1916813",
  // Adjust this value in production, or use tracesSampler for greater control
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Non-Error promise rejection captured with value",
    "Switching tenant...",
  ],
  // By default, Sentry attaches all fetch events as breadcrumbs. Since `apollo-link-sentry` package tracks GraphQL requests as breadcrumbs,
  // they would show up duplicated in Sentry.
  beforeBreadcrumb: excludeGraphQLFetch,
  // Release version
  release: process.env.SENTRY_RELEASE,
  // add stack local variables to stack traces
  includeLocalVariables: true,
});
